import React, { PureComponent } from 'react';
import VirtualizedSelect from 'react-virtualized-select';
import PropTypes from 'prop-types';

import { errorValues, hasDisabledStatus, inputClassNames } from './utils';

export default class SelectField extends PureComponent {
  state = {
    editLocked: true // Used to override disabled with editable fields
  };
  render() {
    const {
      field,
      form: { setFieldValue, setFieldTouched, status },
      options,
      defaultValue,
      label,
      simpleValue,
      isMulti = false,
      loading,
      onChange,
      onAdd,
      onRemove,
      onEdit = false,
      editDisabled = true,
      editLoading = false,
      error = null,
      optionRenderer,
      optionHeight,
      placeholder,
      showValidation,
      clearable,
      disabled,
      style
    } = this.props;
    const loadingSpinner = loading ? (
      <i className="fa fa-fw fa-lg fa-circle-notch fa-spin" />
    ) : null;
    const renderedLabel = label ? (
      <label>
        {label}
        {loadingSpinner}
      </label>
    ) : null;
    const optHeight = optionHeight ? optionHeight : 35;

    const { fieldTouched, fieldError, errorClass } = errorValues(this.props);
    const isDisabledStatus = hasDisabledStatus(status, field.name);
    const classes = showValidation ? inputClassNames(this.props) : null;
    const disabledCalculated = onEdit
      ? editDisabled || this.state.editLocked || editLoading
      : disabled || isDisabledStatus;
    return (
      <div className={errorClass}>
        {label && (
          <label>
            {label} {loadingSpinner}
            {onRemove && !disabled ? (
              <a onClick={() => onRemove()}>
                <i className="fa fa-minus-square fa-fw fa-lg fa-secondary" />
              </a>
            ) : null}
            {onAdd && !disabled ? (
              <a onClick={() => onAdd()}>
                <i className="fa fa-plus-circle fa-fw fa-lg fa-secondary" />
              </a>
            ) : null}
            {onEdit && !editDisabled ? (
              <a style={{ cursor: 'pointer' }} onClick={() => this.setState({ editLocked: false })}>
                {editLoading ? (
                  <i className="fa fa-fw fa-circle-notch fa-spin" />
                ) : (
                  <i className="fa fa-edit fa-fw fa-lg fa-secondary" />
                )}
              </a>
            ) : null}
          </label>
        )}{' '}
        <VirtualizedSelect
          name={field.name}
          clearable={!!clearable}
          className={classes}
          disabled={disabledCalculated}
          multi={isMulti}
          options={options}
          simpleValue={simpleValue}
          value={field.value ? field.value : defaultValue}
          onChange={val => {
            if (onEdit) onEdit(val);
            if (onChange) onChange(val);
            setFieldValue(field.name, val);
          }}
          optionRenderer={optionRenderer}
          optionHeight={optHeight}
          placeholder={placeholder ? placeholder : 'Select...'}
          onBlur={() => setFieldTouched(field.name, true, true)}
          style={style}
        />
        {fieldTouched && fieldError && (
          <span className="has-error">
            {typeof fieldError === 'object' ? 'Required' : fieldError}
          </span>
        )}
        {error && <span className="has-error">{error}</span>}
      </div>
    );
  }
}

SelectField.propTypes = {
  field: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  options: PropTypes.array
};
